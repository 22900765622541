import React, { Fragment } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionTitle,
    AccordionBody,
    IconWrapper,
    OpenIcon,
    CloseIcon,
} from 'common/components/Accordion';
import Heading from 'common/components/Heading';
import Icon from 'react-icons-kit';
import Text from 'common/components/Text';
import Box from 'common/components/Box';
import { plusCircled } from 'react-icons-kit/ionicons/plusCircled';
import { minusCircled } from 'react-icons-kit/ionicons/minusCircled';
import '../faq.style'

function AccordionGroup({questionsObj}) {
    return (
        <Box  id={questionsObj.path.slice(1)}>
            {/* <Text as="h3" content={questionsObj.label} /> */}
            <Accordion >
                <Fragment>
                    
                    <AccordionItem key={`accordion-key--${questionsObj.id}`}>
                        <Fragment>
                        <AccordionTitle className='grey-background'>
                            <Fragment>
                            <Heading as="h3" content={questionsObj.label} />
                            <IconWrapper className="icon-wrapper">
                                <OpenIcon>
                                <Icon icon={minusCircled} size={18} />
                                </OpenIcon>
                                <CloseIcon>
                                <Icon icon={plusCircled} size={18} />
                                </CloseIcon>
                            </IconWrapper>
                            </Fragment>
                        </AccordionTitle>
                        <AccordionBody>
                                <InnerAccordion questions={questionsObj.questions} />
                        </AccordionBody>
                        </Fragment>
                    </AccordionItem>
                    
                </Fragment>
            </Accordion>
        </Box>
    )
}

export default AccordionGroup


const InnerAccordion = ({questions}) => {
    return (
        <Accordion >
            <Fragment>
            {questions.map((item) => (
                <AccordionItem key={`accordion-key--${item.id}`}>
                    <Fragment>
                    <AccordionTitle>
                        <Fragment>
                        <Heading as="h3" content={item.question} />
                        <IconWrapper className="icon-wrapper">
                            <OpenIcon>
                            <Icon icon={minusCircled} size={18} />
                            </OpenIcon>
                            <CloseIcon>
                            <Icon icon={plusCircled} size={18} />
                            </CloseIcon>
                        </IconWrapper>
                        </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                        <Text  dangerouslySetInnerHTML={{__html: item.answer}}/>
                    </AccordionBody>
                    </Fragment>
                </AccordionItem>
            ))}
            </Fragment>
        </Accordion>
    )
}