import React from 'react';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import TrackArea, { Row, Col, ColRight } from './about.style';
import content from '../../../content.json';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { image } = content.about;

  const {t} = useTranslation();
  const textArray = t('about.paragraph', { returnObjects: true });
  return (
    <TrackArea>
      <Container className="Container">
        <Row>
          <Col>
            <Box className="Left">
              {/* <Heading as="h2" content={title} /> */}
              <Image
                src={image}
                alt="track image"
                style={{ borderRadius: '7px' }}
              />
            </Box>
          </Col>
          <ColRight>
            <Box className="Right">
              {textArray.map(({ text }, index) => (
                <Text
                  as="p"
                  key={`track-text-key-${index}`}
                  dangerouslySetInnerHTML={{ __html: t(text) }}
                />
              ))}
            </Box>
          </ColRight>
        </Row>
      </Container>
    </TrackArea>
  );
};

export default About;
