import React, { Fragment } from 'react';
import { Icon } from 'react-icons-kit';
import { plusCircled } from 'react-icons-kit/ionicons/plusCircled';
import { minusCircled } from 'react-icons-kit/ionicons/minusCircled';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Box from 'common/components/Box';

import { SectionHeader } from '../appModern.style';
import FaqSection from './faq.style';
import content from '../../../content.json';
import { ButtonWrap } from '../Pricing/pricing.style';
import { ColLeft, ColRight, Row } from './faq.style';
import AccordionGroup from './components/AccordionGroup';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const { slogan, title, faqs, questions } = content.faq;

  const {t} = useTranslation();
  const questionsTranslated = t('faq.questions', { returnObjects: true });
  return (
    <FaqSection id="faq">
      <Container className="Container">
        <SectionHeader>
          <Heading as="h5" content={t('faq.slogan')} />
          <Heading content={t('faq.title')} />
        </SectionHeader>
        <Row>
          <ColRight>
            {questionsTranslated.map((questionObj) => (
              <AccordionGroup key={questionObj.id} questionsObj={questionObj} />
            ))}
          </ColRight>
        </Row>
      </Container>
    </FaqSection>
  );
};

export default Faq;
