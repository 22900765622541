/* eslint-disable */
import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  ButtonGroup,
} from './banner.style';
import content from '../../../content.json';
import { useTranslation } from 'react-i18next';

const Banner = ({ myRef }) => {

  const {t} = useTranslation();

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading as="h1" content={t("hero.headingText")} />
          </Fade>
          <Fade up delay={200}>
            <Text content={t("hero.heroText")} />
          </Fade>
          <Fade up delay={300}>
            <ButtonGroup>
              <a href={content.hero.buttonLink} target="_blank" ref={myRef}>
                <Button className="primary" title={t("hero.button")} />
              </a>
            </ButtonGroup>
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            {/* <Image src="/hero/naslovna1-min.png" /> */}
          </Fade>
        </BannerImage>
      </Container>
      <img
        className="bannerBottomShape"
        src="/hero/shape.svg"
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
