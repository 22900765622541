import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TabWrapper, {
  TabMenu,
  MenuItem,
  TabContent,
  TabPanel,
} from './tabs.style';

const Tab = ({ active, className, children }) => {
  const [activeIndex, setActiveIndex] = useState(active || 0);

  const handleChange = (index) => {
    setActiveIndex(index);
  };

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    // Your custom logic here
    // setActiveIndex(Math.floor(Math.random() * 5))
  }, 3000);

  const addAllClasses = ['rq_tab'];
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <TabWrapper className={addAllClasses.join(' ')}>
      <TabMenu className="tab_menu">
        {children.map((element, index) => {
          return (
            <MenuItemInner
              element={element}
              index={index}
              handleChange={handleChange}
              activeIndex={activeIndex}
            />
          );
        })}
      </TabMenu>
      <TabContent className="tab_content">{children[activeIndex]}</TabContent>
    </TabWrapper>
  );
};

const MenuItemInner = ({ index, element, handleChange, activeIndex }) => {
  const [isThisElementActive, setIsThisElementActive] = useState(
    index === activeIndex
  );

  const innerHandleChange = () => {
    handleChange(index);
  };

  useEffect(() => {
    setIsThisElementActive(index === activeIndex);
  }, [activeIndex]);

  return (
    <MenuItem
      key={index}
      className={isThisElementActive ? 'active' : ''}
      onClick={innerHandleChange}
      style={{ minWidth: '340px' }}
    >
      {isThisElementActive ? element.props.description : element.props.title}
    </MenuItem>
  );
};

export const Panel = ({ children }) => (
  <TabPanel className="tab_panel">{children}</TabPanel>
);

Tab.propTypes = {
  children: PropTypes.array,
  active: PropTypes.number.isRequired,
};

Panel.propTypes = {
  children: PropTypes.element,
  title: PropTypes.element.isRequired,
};

export default Tab;
