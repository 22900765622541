import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'common/components/Image';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import FooterArea, {
  Left,
  Menu,
  CopyText,
  Social,
  SocialText,
} from './footer.style';
import content from '../../../content.json';

const Footer = () => {
  const { menu, logo, social } = content.footer;
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea style={{ backgroundColor: '#26292E' }}>
      <Container className="Container">
        <Left>
          <Logo className="logo" logoSrc={logo} width="120px" />
          <CopyText style={{ color: '#FFF' }}>
            Copyright © {year} Crystal Pigeon d.o.o
          </CopyText>
        </Left>
        {/* <Menu>
          {menu.map(({ link, label }, index) => (
            <Link target="_blank" href={link} key={`footer-link-key-${index}`}>
              {label}
            </Link>
          ))}
        </Menu> */}
        <Social>
          <a
            href="mailto:skenitapp@gmail.com"
            target="_blank"
            style={{ color: '#FFF' }}
          >
            skenitapp@gmail.com
          </a>
          <div style={{ display: 'flex' }}>
            {social.map(({ link, icon }, index) => (
              <Link
                target="_blank"
                to={link}
                key={`footer-social-key-${index}`}
              >
                <Image src={icon} alt="social image" />
              </Link>
            ))}
          </div>
        </Social>
      </Container>
    </FooterArea>
  );
};

export default Footer;
