import React, { useState, useEffect } from 'react';
import Heading from 'common/components/Heading';
import { Icon } from 'react-icons-kit';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { closeCircled } from 'react-icons-kit/ionicons/closeCircled';
import Container from 'common/components/UI/Container';
import Button from 'common/components/Button';
import { SectionHeader } from '../appModern.style';
import SectionWrapper, {
  SectionBgArea,
  ContentWrapper,
  ContentPricing,
  PriceTable,
  PricingFeature,
  FeatureItem,
  ContentWrap,
  ButtonWrap,
  ButtonGroup,
} from './pricing.style';
import content from '../../../content.json';
import { useTranslation } from 'react-i18next';

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const Pricing = () => {
  const size = useWindowSize();
  const isTablet = Boolean(size.width <= 768);
  const { slogan, title, pricingFeature, pricingItems } = content.pricing;

  const [activePlan, setPlan] = useState(0);
  const monthly = 0;
  const annualy = 1;


  const {t} = useTranslation();
  const pricingFeaturesTranslated = t('pricing.pricingFeature', { returnObjects: true });
  const pricingItemsTranslated = t('pricing.pricingItems', { returnObjects: true });
  return (
    <SectionWrapper id="pricing">
      <SectionBgArea></SectionBgArea>
      <Container>
        <ContentWrapper>
          <SectionHeader>
            <Heading as="h5" content={t("pricing.title")} />
            <Heading content={t("pricing.slogan")} />
          </SectionHeader>

          <ButtonGroup>
            <button
              className={activePlan === monthly ? 'active' : ''}
              type="button"
              onClick={() => setPlan(monthly)}
            >
              {' '}
              {t("pricing.monthly")}{' '}
            </button>
            <button
              className={activePlan === annualy ? 'active' : ''}
              type="button"
              onClick={() => setPlan(annualy)}
            >
              {' '}
              {t("pricing.annualy")}{' '}
            </button>
          </ButtonGroup>

          <ContentPricing>
            {!isTablet && (
              <PricingFeature>
                {pricingFeaturesTranslated.map((feature) => (
                  <FeatureItem key={feature.id}>{feature.name}</FeatureItem>
                ))}
              </PricingFeature>
            )}
            {pricingItemsTranslated.map((priceTable) => (
              <PriceTable
                key={priceTable.id}
                className={priceTable.isRecommended && 'isRecommended'}
              >
                {priceTable.isRecommended && (
                  <div className="recommended">Preporučeno</div>
                )}

                <h2 className="title">{priceTable.package_name}</h2>

                <div className="price">
                  {content.pricing.currency}
                  {activePlan === annualy
                    ? priceTable.priceAnnual
                    : priceTable.price}
                </div>

                <ul className="featureList">
                  {priceTable.features
                    .filter((feature) =>
                      isTablet ? feature.isAvailable : true
                    )
                    .map((feature) => (
                      <FeatureItem key={feature.id}>
                        {!isTablet ? (
                          feature.count != null ? (
                            <p>{feature.count}</p>
                          ) : feature.isAvailable ? (
                            <Icon
                              icon={ic_check_circle}
                              size={24}
                              style={{ color: '#3CC68A' }}
                            />
                          ) : (
                            <Icon
                              icon={closeCircled}
                              size={24}
                              style={{ color: '#F2A143', opacity: '0.25' }}
                            />
                          )
                        ) : (
                          <p>{feature.name}</p>
                        )}
                      </FeatureItem>
                    ))}
                  {/* <li >
                      <a href="http://onelink.to/skenitadmin">
                        <Button
                          className="choosePlan"
                          title={content.pricing.choosePlan}
                        ></Button>
                      </a>    
                    </li> */}
                </ul>
              </PriceTable>
            ))}
          </ContentPricing>
          {content.pricing.notes.map((note) => (
            <p style={{ color: '#0f2137', opacity: '0.5' }}>• {note}</p>
          ))}
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Pricing;
