import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Tab, { Panel } from 'common/components/Tabs';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../appModern.style';
import SectionWrapper, {
  TabArea,
  ButtonGroup,
  ButtonDiv,
} from './clientApp.style';
import content from '../../../content.json';
import Button from 'common/components/Button';
import Flicking from '@egjs/react-flicking';
import { Fade as FFade } from '@egjs/flicking-plugins';
import '@egjs/react-flicking/dist/flicking-inline.css';
import '@egjs/flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import { AutoPlay } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/arrow.css';
import { BrowserView, isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const _plugins = [
  new FFade(),
  new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: false }),
];

const ClientApp = () => {
  const { slogan, title, tab } = content.clientApp;
  const {t} = useTranslation();
  const clientTabs = t('clientApp.tab', { returnObjects: true });
  return (
    <SectionWrapper id="clientApp">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={t("clientApp.slogan")} style={{ color: '#fff' }} />
            <Heading content={t("clientApp.title")} />
          </Fade>
        </SectionHeader>
        <BrowserView>
          <Fade up delay={100}>
            <TabArea>
              <Tab active={0}>
                {clientTabs.map((item) => (
                  <Panel
                    title={
                      <FeatureBlock
                        style={{ '--color': `#000` }}
                        iconPosition="left"
                        icon={
                          <Fragment>
                            <Image
                              src={item.icon}
                              style={{
                                width: '35px',
                                filter: 'brightness(0.2)',
                              }}
                            />
                          </Fragment>
                        }
                        title={
                          <Heading
                            as="h2"
                            content={item.title}
                            style={{
                              fontWeight: 'normal',
                              marginBottom: '0px',
                              color: '#26292E',
                            }}
                          />
                        }
                      />
                    }
                    description={
                      <FeatureBlock
                        style={{ '--color': `#000` }}
                        iconPosition="left"
                        icon={
                          <Fragment>
                            <Image
                              src={item.icon}
                              style={{
                                width: '35px',
                                filter: 'brightness(0.2)',
                              }}
                            />
                          </Fragment>
                        }
                        title={
                          <Text
                            content={item.description}
                            style={{ lineHeight: '1.3', color: '#26292E' }}
                          />
                        }
                      />
                    }
                    key={`app-tab--key${item.id}`}
                  >
                    <Fade>
                      <Image
                        src={item.image}
                        style={{ width: 'auto', height: '75vh' }}
                      />
                    </Fade>
                  </Panel>
                ))}
              </Tab>
            </TabArea>
          </Fade>
        </BrowserView>

        {!isBrowser ? (
          <span>
            <h1 style={{ display: 'none' }}>text</h1>
            <Flicking
              circular={true}
              plugins={_plugins}
              className="padding-bottom"
            >
              {clientTabs.map((item) => (
                <div key={item.id} style={{ margin: '0px 30px' }}>
                  <Image src={item.image} width="250px" alt="sc" />
                </div>
              ))}
            </Flicking>
          </span>
        ) : (
          <></>
        )}
      </Container>
        <ButtonDiv>
          <ButtonGroup>
            <a href={content.hero.buttonLinkClient} target="_blank">
              <Button className="primary" title={t('hero.button')} />
            </a>
          </ButtonGroup>
        </ButtonDiv>
    </SectionWrapper>
  );
};

export default ClientApp;
