import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { mediaRecordOutline } from 'react-icons-kit/typicons/mediaRecordOutline';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Tab, { Panel } from 'common/components/Tabs';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../appModern.style';
import SectionWrapper, {
  TabArea,
  ButtonGroup,
  ButtonDiv,
} from './adminApp.style';
import content from '../../../content.json';
import Button from 'common/components/Button';
import Flicking from '@egjs/react-flicking';
import { Fade as FFade } from '@egjs/flicking-plugins';
import '@egjs/react-flicking/dist/flicking-inline.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import { AutoPlay } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/arrow.css';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import '@egjs/flicking/dist/flicking.css';
import { useTranslation } from 'react-i18next';

const _plugins = [
  new FFade(),
  new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: false }),
];

const AdminApp = () => {
  const { slogan, title, tab } = content.adminApp;
  const {t} = useTranslation();
  const adminTabs = t('adminApp.tab', { returnObjects: true });
  return (
    <SectionWrapper id="adminApp" style={{ backgroundColor: '#26292E' }}>
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={t("adminApp.slogan")} />
            <Heading content={t("adminApp.title")} style={{ color: 'white' }} />
          </Fade>
        </SectionHeader>
        <BrowserView>
          <Fade up delay={100}>
            <TabArea>
              <Tab active={0}>
                {adminTabs.map((item) => (
                  <Panel
                    title={
                      <FeatureBlock
                        style={{ '--color': `#F2A143` }}
                        iconPosition="left"
                        icon={
                          <Fragment>
                            <Image src={item.icon} style={{ width: '35px' }} alt='screenshot'/>
                          </Fragment>
                        }
                        title={
                          <Heading
                            as="h2"
                            content={item.title}
                            style={{
                              fontWeight: 'normal',
                              marginBottom: '0px',
                              color: '#fff',
                            }}
                          />
                        }
                      />
                    }
                    description={
                      <FeatureBlock
                        style={{ '--color': `#F2A143` }}
                        iconPosition="left"
                        icon={
                          <Fragment>
                            <Image src={item.icon} style={{ width: '35px' }} alt='screenshot'/>
                          </Fragment>
                        }
                        title={
                          <Text
                            content={item.description}
                            style={{ lineHeight: '1.3', color: '#fff' }}
                          />
                        }
                      />
                    }
                    key={`app-tab--key${item.id}`}
                  >
                    <Fade>
                      <Image
                        src={item.image}
                        style={{ width: 'auto', height: '75vh' }}
                        alt='screenshot'
                      />
                    </Fade>
                  </Panel>
                ))}
              </Tab>
            </TabArea>
          </Fade>
        </BrowserView>

        {!isBrowser ? (
          <span>
            <h1 style={{ display: 'none' }}>text</h1>
            <Flicking
              circular={true}
              plugins={_plugins}
              className="padding-bottom"
            >
              {adminTabs.map((item, index) => (
                <div key={index} style={{ margin: '0px 30px' }}>
                  <Image src={item.image} width="250px" alt="sc" />
                </div>
              ))}
            </Flicking>
          </span>
        ) : (
          <></>
        )}
      </Container>
        <ButtonDiv>
          <ButtonGroup>
            <a href={content.hero.buttonLink} target="_blank">
              <Button className="primary" title={t('hero.button')} />
            </a>
          </ButtonGroup>
        </ButtonDiv>
    </SectionWrapper>
  );
};

export default AdminApp;
