import React, { useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import content from '../../../content.json';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import { useTranslation } from 'react-i18next';
import {language} from 'react-icons-kit/fa/language'
import {chevronDown} from 'react-icons-kit/fa/chevronDown'
import DropdownMenu from '../../../common/components/Dropdown';
import {
  DropdownMenuWrapper,
  DropdownMenuItemsWrapper,
  DropdownMenuItemWrapper,
} from '../../../common/components/Dropdown/dropdown.style';
const Navbar = ({ buttonVisible }) => {
  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false,
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    if (type === 'search') {
      setState({
        ...state,
        search: '',
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const scrollItems = [];

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang === 'ENG' ? 'en' : 'sr')
  }

  const {t, i18n} = useTranslation();
  const translatedNavItems = t('navMenu', { returnObjects: true });

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href=""
          logoSrc="/logoTextOrange.svg"
          title="Skenit"
          className="main-logo"
        />
        <Logo
          href=""
          logoSrc="/logoText.svg"
          title="Skenit"
          className="logo-alt"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? 'active' : ''}>
          <ScrollSpyMenu
            className="menu"
            menuItems={content.navMenu}
            offset={-84}
          />
          {/* end of main menu */}

          <DropdownMenu content={<span style={{display: 'flex', justifyContent: 'center'}}>
            {i18n.language === 'sr' ? 'SRP' : 'ENG'}
            <Icon icon={chevronDown} style={{display: 'flex', alignSelf: 'baseline', marginLeft: '5px'}}/>
            </span>}
            dropdownItems={['SRP', 'ENG']}
            customFunc={handleLanguage}
          />

          {!buttonVisible ? (
            <a href="http://onelink.to/skenitadmin" target="_blank">
              <Button className="actionButton" title={t('hero.contact')} />
            </a>
          ) : (
            <span></span>
          )}

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>            
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={scrollItems}
            offset={-84}
            currentClassName="active"
          >
            {translatedNavItems.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <AnchorLink
                  href={menu.path}
                  target="_top"
                  offset={menu.offset}
                  onClick={handleRemoveMenu}
                >
                  {menu.label}
                </AnchorLink>
              </li>
            ))}
          </Scrollspy>
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
